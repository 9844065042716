import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Head from "../components/head"

import bodyStyles from '../sass/components/body.module.scss'
import activityStyles from '../sass/pages/activity.module.scss'
import commonStyles from '../sass/components/common.module.scss'
import houseIconSvg from '../assets/icons/vcd-house-color.svg'
import sunsetIconSvg from '../assets/icons/vcd-sunset-color.svg'
import mountainIconSvg from '../assets/icons/vcd-mountain-color.svg'

import { 
  houseIconAlt,
  sunsetIconAlt,
  mountainIconAlt
} from '../constants/iconAlt'

const bS = bodyStyles
const aS = activityStyles
const cmS = commonStyles

const AboutPage = () => {
  return (
    <Layout>
      <Head title={"About"} />
      <div className={bS.container}>

      <h1 className={cmS.title}> Activités en Provence </h1>
      <div className={cmS.iconWrapperWrapper}>
      <div className={cmS.iconWrapper}>
        <img className={cmS.box1Icon} src={sunsetIconSvg} alt={sunsetIconAlt} />
        <img className={cmS.box2Icon} src={houseIconSvg} alt={houseIconAlt} />
        <img className={cmS.box4Icon} src={mountainIconSvg} alt={mountainIconAlt} />
      </div>
      </div>

      <div className={cmS.mainComponentWrapper}>
      <h1 className={cmS.subtitle}>Pousser la découverte plus loin grâce aux professionnels locaux...</h1>
      <div className={aS.paragraphWrapper}>
        <div className={aS.paragraphBox}>
          <div className={cmS.paragraph}>
            <p>
            Adrénaline, matière grise ou Activités en Provence. A venir, dans cette section, un recueil d'activités possible dans la région Provence-Alpes-Côtes d'Azur, détaillant lieu, distance depuis Salernes, durée, niveau de santé nécessaire éventuel...
              <br/><br/>
             Texte..
          </p>
          </div>
        </div>
        <div className={aS.paragraphBox}>
        <div className={cmS.paragraph}>
          <p>
            En bref, vous trouverez ici sensiblement plus que ce que Google pourrait vous dire, bénéficiant de près de 20 ans d'expérience de Gérard et et des retours de vacanciers.
            <br/><br/>
           Texte..
           <br/><br/>
           Parlons de <Link className={aS.email} to="/contact">vos prochaines vacances!</Link>
        </p>
        </div>
      </div>
      </div>
    </div>
    </div>

    </Layout>
  )
}

export default AboutPage
